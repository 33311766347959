import React from 'react';
import defaultColor from '../theme';

export default ({ color = defaultColor }) => (
  <svg viewBox="0 0 100 100">
    <path
      d="M5 23.735v34.954h12.755c.231 0 .293-.276.174-.418-.184-.221-.264-.32-.585-.763a6.19 6.19 0 0 1-1.183-4.027c.109-1.838 1.077-3.314 2.292-4.234a6.714 6.714 0 0 1 4.024-1.366c1.366 0 2.808.446 4.023 1.366 1.216.92 2.184 2.396 2.293 4.234a6.19 6.19 0 0 1-1.183 4.027c-.321.443-.4.542-.586.763-.119.142-.056.418.174.418h12.756V46.006c0-1.312.772-2.229 1.729-1.976.796.21 1.9 2.312 4.755 2.142 2.854-.17 4.323-2.863 4.323-4.962 0-2.1-1.469-4.79-4.323-4.96-2.855-.17-3.959 1.93-4.755 2.14-.957.253-1.73-.643-1.73-1.955v-12.7z"
      overflow="visible"
    />
    <path fill={color} d="M22.477 49.239c-2.1 0-4.79 1.469-4.96 4.323-.17 2.855 1.93 3.959 2.14 4.755.253.957-.643 1.73-1.955 1.73h-12.7V95h34.953V82.245c0-.231-.275-.293-.417-.174-.222.184-.32.264-.763.585a6.19 6.19 0 0 1-4.027 1.183c-1.838-.109-3.314-1.077-4.234-2.292a6.714 6.714 0 0 1-1.366-4.024c0-1.366.446-2.808 1.366-4.023.92-1.216 2.396-2.184 4.234-2.293a6.19 6.19 0 0 1 4.027 1.183c.443.321.541.4.763.586.142.119.417.056.417-.174V60.046H27.273c-1.312 0-2.23-.772-1.976-1.729.21-.796 2.312-1.9 2.142-4.755-.17-2.854-2.863-4.323-4.962-4.323z" />
    <path
      d="M41.312 60.044v12.684c0 1.312-.772 2.228-1.729 1.975-.796-.211-1.9-2.312-4.754-2.142-2.854.17-4.323 2.863-4.323 4.963 0 2.099 1.469 4.79 4.323 4.96 2.855.17 3.958-1.93 4.754-2.141.957-.253 1.73.643 1.73 1.955v12.7h34.954V60.044H63.22c-1.217 0-2.048.717-1.814 1.605.196.74 2.144 1.764 1.986 4.413-.157 2.649-2.655 4.01-4.603 4.01s-4.446-1.361-4.604-4.01c-.157-2.649 1.792-3.674 1.988-4.413.234-.888-.617-1.605-1.835-1.605zM64.48 52.56l11.075-6.183c1.145-.64 2.321-.412 2.567.547.205.798-1.092 2.786.447 5.195 1.54 2.41 4.608 2.378 6.441 1.355 1.833-1.023 3.467-3.617 2.223-6.192-1.243-2.575-3.614-2.515-4.186-3.108-.688-.712-.282-1.823.864-2.462L95 35.52 77.96 5 47.44 22.04l6.36 11.39c.593 1.064 1.625 1.44 2.286.802.55-.53.495-2.73 2.884-3.885 2.39-1.153 4.796.363 5.746 2.064.95 1.7.979 4.546-1.257 5.975-2.236 1.429-4.081.226-4.822.416-.89.228-1.1 1.321-.507 2.384z"
      overflow="visible"
    />
  </svg>
);
