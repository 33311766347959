import React from 'react';
import defaultColor from '../theme';

export default ({ color = defaultColor }) => (
  <svg viewBox="0 0 70 70">
    <path d="M3 68h64a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v64a1 1 0 0 0 1 1zm1-2V19.464h62V66H4zM66 4v13.464H4V4h62z" />
    <path d="M57.147 11.732h3.436a1 1 0 0 0 0-2h-3.436a1 1 0 0 0 0 2zM48.594 11.732h3.437a1 1 0 0 0 0-2h-3.437a1 1 0 0 0 0 2zM40.042 11.732h3.436a1 1 0 0 0 0-2h-3.436a1 1 0 0 0 0 2zM9.417 11.732H33.25a1 1 0 0 0 0-2H9.417a1 1 0 0 0 0 2zM11.323 25.796h47.5a1 1 0 0 0 0-2h-47.5a1 1 0 0 0 0 2zM11.323 31.796h47.5a1 1 0 0 0 0-2h-47.5a1 1 0 0 0 0 2zM34.637 45.478l-3.245-.367 2.035-2.554a1 1 0 0 0-.075-1.33l-3.431-3.431a.999.999 0 0 0-1.33-.075l-2.554 2.035-.367-3.245a1 1 0 0 0-.994-.888h-4.852a1 1 0 0 0-.994.888l-.367 3.245-2.554-2.035a.999.999 0 0 0-1.33.075l-3.431 3.431a1 1 0 0 0-.075 1.33l2.035 2.554-3.245.367a1 1 0 0 0-.887.994v4.852a1 1 0 0 0 .887.994l3.245.367-2.035 2.554a1 1 0 0 0 .075 1.33L14.579 60a1 1 0 0 0 1.33.075l2.554-2.035.367 3.245a1 1 0 0 0 .994.887h4.852a1 1 0 0 0 .994-.887l.367-3.245 2.554 2.035A1 1 0 0 0 29.92 60l3.431-3.431a1 1 0 0 0 .075-1.33l-2.035-2.554 3.245-.367a1 1 0 0 0 .887-.994v-4.852a1 1 0 0 0-.887-.994zm-1.113 4.953l-3.868.437a1 1 0 0 0-.813.613l-.05.11a20.96 20.96 0 0 0-.049.103 1 1 0 0 0 .128 1.038l2.434 3.055-2.167 2.167-3.121-2.487c-.268-.215-.721-.24-1.044-.129l-.116.043a1 1 0 0 0-.626.818l-.45 3.973h-3.065l-.449-3.973a1 1 0 0 0-.626-.818l-.153-.055a1.005 1.005 0 0 0-1.007.14l-3.12 2.488-2.168-2.167 2.434-3.055a1 1 0 0 0 .128-1.038l-.048-.103-.053-.114a1 1 0 0 0-.81-.61l-3.87-.436v-3.066l3.748-.424a1 1 0 0 0 .827-.65l.05-.14c.022-.065.044-.13.07-.193a1 1 0 0 0-.137-1.014l-2.34-2.935 2.169-2.167 2.87 2.287a1 1 0 0 0 1.04.126c.131-.06.264-.116.4-.168a1 1 0 0 0 .634-.82l.411-3.644h3.066l.411 3.643a1 1 0 0 0 .635.82c.135.053.268.11.399.17a1 1 0 0 0 1.04-.127l2.87-2.287 2.168 2.167-2.339 2.935a1 1 0 0 0-.138 1.014c.027.063.049.128.071.193l.05.14a1 1 0 0 0 .827.65l3.747.424v3.066z" />
    <path fill={color} d="M22.25 43.277c-2.924 0-5.304 2.38-5.304 5.304s2.38 5.303 5.304 5.303 5.304-2.379 5.304-5.303-2.38-5.304-5.304-5.304zm0 8.607a3.307 3.307 0 0 1-3.304-3.303c0-1.822 1.482-3.304 3.304-3.304s3.304 1.482 3.304 3.304a3.307 3.307 0 0 1-3.304 3.303zM57.282 36.96H43.865a3.045 3.045 0 0 0-3.042 3.042V56.54a3.045 3.045 0 0 0 3.042 3.042h13.417a3.045 3.045 0 0 0 3.041-3.042V40.002a3.045 3.045 0 0 0-3.041-3.041zm1.041 19.58c0 .574-.467 1.042-1.041 1.042H43.865a1.043 1.043 0 0 1-1.042-1.042V40.002c0-.574.468-1.041 1.042-1.041h13.417c.574 0 1.041.467 1.041 1.041V56.54z" />
    <path d="M55.157 42.063H45.99a1 1 0 0 0 0 2h9.167a1 1 0 0 0 0-2zM55.157 47.271H45.99a1 1 0 0 0 0 2h9.167a1 1 0 0 0 0-2zM55.157 52.48H45.99a1 1 0 0 0 0 2h9.167a1 1 0 0 0 0-2z" />
  </svg>
);
