import styled from 'styled-components';

export const InfoBar = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-size: 1.3em;
  flex-wrap: wrap;

  & a {
    text-decoration: none;
    opacity: 0.6;
    margin 0 1em;
  }

  margin: 2em auto;
  line-height: 2em;
  max-width: 30em;
`;

export const InfoTitle = styled.div`
  display: flex;
  justify-content: center;
  font-size: 1.6em;

  margin-bottom: 1em;
`;
