import React from 'react';
import styled from 'styled-components';

const Box = styled.div`
  border: 1px solid #ededed;
  padding: 15px 10px;
  margin: 20px;
  border-radius: 2px;
  min-width: 250px;

  flex: 1 1 250px;
  background-color: white;

  text-align: center;

  & > p {
    font-size: 14px;
    color: grey;
  }
`;
const BoxImage = styled.div`
  fill: #999;
  height: 80px;
  display: flex;

  & > svg {
    height: 100%;
    width: 100%;
  }
`;

const BoxTitle = styled.div`
  font-size: 16px;
  margin-bottom: 20px;
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  max-width: 960px;
  margin: auto;
`;

export default ({ title, Illustration, children }) => (
  <Box>
    <BoxTitle>{title}</BoxTitle>
    <BoxImage>
      <Illustration />
    </BoxImage>
    {children}
  </Box>
);
