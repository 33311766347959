import React from 'react';
import defaultColor from '../theme';

export default ({ color = defaultColor }) => (
  <svg viewBox=" 0 0 100 100">
    <path d="M99.4 40.7L84.1 33l15.7-7.8L50 3.6.2 25.2 15.9 33 .6 40.7l15.5 7.7L.6 56.2l15.3 7.6-15.2 8L50 96.4l49.3-24.6-15.2-8 15.3-7.6-15.5-7.8 15.5-7.7zM50 6.9l42.7 18.5L50 46.7 7.3 25.4 50 6.9zm42.7 49.3L50 77.5 7.3 56.2l12.1-6.1L50 65.3 80.6 50l12.1 6.2zM50 62L7.3 40.7l11.9-6L50 50l30.8-15.3 11.9 6L50 62z" />
    <path
      fill={color}
      d="M16.1 48.4l-3.378 1.7L.6 56.2l15.3 7.6L50 81.4l34.1-17.6 15.3-7.6-15.5-7.8-3.3 1.6 12.1 6.2L50 77.5 7.3 56.2l12.1-6.1"
    />
  </svg>
);
