import React from 'react';
import styled, { css } from 'styled-components/macro';

import { lighten, darken, desaturate } from 'polished';
import { compose } from 'ramda';
import color from '../theme';

const baseColor = color;
const lighterColor = darken(0.1, color);
const neutralLightColor = compose(
  desaturate(0.2),
  lighten(0.5)
)(baseColor);
const desaturatatedDarkColor = compose(desaturate(0.3))(lighterColor);

export const ButtonBar = styled.div`
  display: flex;
  justify-content: flex-end;

  & > button:not(:last-child) {
    margin-right: 10px;
  }
`;

const setDisabled = ({ disabled }) => {
  if (!disabled) return null;
  return css`
    &,
    &:hover {
      cursor: not-allowed;
      background-color: ${desaturatatedDarkColor};
      border-color: ${desaturatatedDarkColor};
      color: white;
    }

    &:active {
      transform: initial;
    }
  `;
};

export const Button = styled.button.attrs({ tabIndex: 1 })`
  flex: 0 1 auto;
  color: #444;
  fill: #444;
  background-color: ${neutralLightColor};
  padding: 5px 10px 5px 10px;

  border: solid 1px ${neutralLightColor};
  border-radius: 2px;

  font-weight: lighter;

  cursor: pointer;

  &:active {
    transform: translateY(1px);
  }

  &:hover {
    color: white;
    fill: white;
    font-weight: lighter;
    background-color: ${lighterColor};
    border-color: ${lighterColor};
  }

  &:focus {
    outline: none;
    border-color: ${lighterColor};
  }

  ${setDisabled};
  text-decoration: none;
`;

export const ActionButton = styled(Button)`
  color: white;
  fill: white;
  background-color: ${baseColor};
  border-color: ${baseColor};

  &:hover {
    color: white;
    fill: white;
    background-color: ${lighterColor};
    border-color: ${lighterColor};
  }

  &:focus {
    border-color: ${lighterColor};
  }
`;

export const CancelButton = styled(Button)`
  background-color: transparent;
  color: ${baseColor};
  fill: ${baseColor};

  border-color: transparent;

  &:hover {
    color: ${baseColor};
    fill: ${baseColor};

    background-color: #0000002b;
    border-color: transparent;
  }

  &:focus {
    border-color: #0000002b;
    border-width: 1px;
  }

  ${setDisabled};
`;

export const LinkButton = styled(Button.withComponent('a'))`
  text-decoration: none;
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const BigSpan = styled.span`
  @media (max-width: ${props => props.breakPoint}) {
    display: none;
  }
`;

const WrapperSpan = styled.span`
  flex: 0 0 auto;
  & > :not(:first-child) {
    margin-left: 5px;
  }
`;

export const ReactiveButton = ({
  smallContent,
  bigContent,
  breakPoint = '1000px',
  ButtonComponent = Button,
  ...props
}) => (
  <ButtonComponent {...props}>
    <WrapperSpan>
      <span>{smallContent}</span>
      <BigSpan breakPoint={breakPoint}>{bigContent}</BigSpan>
    </WrapperSpan>
  </ButtonComponent>
);

export const TextButton = styled.button`
  flex: 0 0 auto;
  font-size: 0.8em;
  cursor: pointer;
  padding: 5px;
  color: ${baseColor};
  fill: ${baseColor};
  &:hover {
    color: ${lighterColor};
    fill: ${lighterColor};
  }
  background-color: transparent;
  border: none;
  border-radius: 2px;
`;

export const MenuButton = styled.button.attrs({ tabIndex: -1 })`
  border: none;
  background-color: #ecf0f1;
  border-radius: 2px;
  padding: 0px;

  fill: #444;

  width: 2em;
  height: 2em;

  display: flex;
  justify-content: center;
  align-items: center;

  border: 2px solid #ecf0f1;

  font-weight: lighter;
  cursor: pointer;

  &:hover {
    background-color: ${lighterColor};
    border-color: ${lighterColor};
    stroke-width: 2px;
    fill: white;
  }

  &:focus {
    outline: none;
  }
`;
