import React from 'react';
import defaultColor from '../theme';

export default ({ color = defaultColor }) => (
  <svg viewBox="0 0 96 96">
    <path d="M90 46.5H6a2 2 0 0 0 0 4h84a2 2 0 0 0 0-4zM49.637 59.352a2.08 2.08 0 0 0-3.274 0l-14 20A2 2 0 0 0 34 82.5h28a2 2 0 0 0 1.637-3.148z" />
    <rect x="12" y="14.5" width="24" height="24" rx="2" ry="2" />
    <rect fill={color} x="60" y="14.5" width="24" height="24" rx="2" ry="2" />
  </svg>
);
