import React from 'react';
import styled from 'styled-components';
import { darken } from 'polished';

import { CancelButton, ButtonBar } from './Button.jsx';
import color from '../theme';

const HeroText = styled.h1`
  text-align: center;
  font-size: 32px;
  font-weight: normal;
`;

const HeroWrapper = styled.div`
  min-height: 100px;
  font-size: 32px;
  color: white;

  padding: 20px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: linear-gradient(${color}, ${darken(0.2, color)});
  box-shadow: 0 4px 11px rgba(124, 146, 169, 0.5);
`;

export const Bar = styled(ButtonBar)`
  display: flex;
  justify-content: flex-end;

  & > *:not(:last-child) {
    margin-right: 10px;
  }

  margin-top: 40px;
  font-size: 24px;
  color: white;
`;

export const AppButton = styled(CancelButton)`
  fill: white;
  color: white;

  &:hover {
    fill: white;
    color: white;
  }
`;

export default ({ children }) => (
  <HeroWrapper>
    <HeroText>{children}</HeroText>
  </HeroWrapper>
);
