import React from 'react';

export default ({ size = '1em' }) => (
  <>
    <svg
      width={size}
      viewBox="0 0 100 100"
      fillRule="evenodd"
      clipRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
    >
      <path
        d="M40.3 88.5l-7.5-6.1c-.1-.1-.3-.2-.4-.2H20.6c-.5 0-.8-.4-.8-.9V43.9c0-1.5.5-2.9 1.5-4l7-10.1c.2-.3.5-.5.9-.5h14.5c.4 0 .7.2.9.5l7 10.1c1 1.1 1.5 2.5 1.5 4v11.2c0 .6.8.7 1 .2 1.2-2.6 3.2-4.8 5.6-6.2.2-.1.3-.3.3-.6v-7.3c0-1.7-.6-3.3-1.7-4.6l-10.7-14c-.3-.4-.8-.7-1.3-.7H26.6c-.5 0-1 .2-1.3.7l-10.7 14c-1.1 1.2-1.7 2.9-1.7 4.6v42.1c0 3.6 2.7 6.5 6.1 6.5h20.9c.6-.1.9-.8.4-1.3zM19.9 7.4v8.8c0 1.3 1.3 2.4 3 2.4h27.2c1.6 0 3-1.1 3-2.4V7.4c0-1.3-1.3-2.4-3-2.4H22.9c-1.6 0-3 1.1-3 2.4zm5.7.1c.2 0 .3.1.3.3V16c0 .1-.1.3-.3.3h-2.5c-.2 0-.3-.1-.3-.3V7.8c0-.1.1-.3.3-.3h2.5z"
        fillRule="nonzero"
      />
      <path
        d="M64 76.6c-.2-.1-.3-.3-.5-.4L47.8 63.3c-2.1-1.7-4.8-2.7-7.5-2.5-3 .2-5.7 1.5-7.6 3.8-3.8 4.6-3.1 11.4 1.5 15.2l15.5 12.8c4.5 3.7 11.2 3.2 15-1.2.3-.4.7-.8.9-1.3.1-.2.1-.5 0-.7-2.4-3.5-3-8-1.6-12 .2-.4.2-.6 0-.8zm-11.4-2.4l-6.7 8.2c-.2.3-.7.3-1 .1l-7.7-6.3C35.8 75 35 73.3 35 71.6c0-1.3.4-2.7 1.4-3.8 2.1-2.5 5.8-2.9 8.4-.8l7.7 6.3c.3.2.4.6.1.9zM83.2 73.5c-4.5-3.5-11-2.7-14.5 1.8s-2.7 11 1.8 14.5 11 2.7 14.5-1.8c3.5-4.4 2.7-10.9-1.8-14.5zm.1 11.8l-1.4 1.8c-.1.2-.4.2-.6.1l-11.1-8.7c-.2-.1-.2-.4-.1-.6l1.4-1.8c.1-.2.4-.2.6-.1l11.1 8.7c.2.2.2.4.1.6zM76.8 60.7c0-5.7-4.6-10.3-10.3-10.3-5.7 0-10.3 4.6-10.3 10.3 0 5.7 4.6 10.3 10.3 10.3 5.7.1 10.3-4.5 10.3-10.3zm-17.9 1.2v-2.3c0-.2.2-.4.4-.4h14.1c.2 0 .4.2.4.4v2.3c0 .2-.2.4-.4.4H59.3c-.2 0-.4-.2-.4-.4z"
        fillRule="nonzero"
      />
    </svg>
  </>
);
